import {ProjectsHelper} from "./../ProjectsHelper";

export class InvoicesNew {
  static fetchProjects(jquery: any): void {
    ProjectsHelper.fetchProjects(jquery,
                                 jquery('#invoice_customer_id option:selected').val(),
                                 '#invoice_project_ids',
                                 '#project_list_error',
                                 '#project_list_error_code');
  }
}
