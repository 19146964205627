export class ProjectsHelper {
  static buildSelect(jQuery: any, fieldId: string, errorFieldId: string, data: any) {
    const options = data.map((project) => {
      return "<option id=\"" + project[`id`] + "\" value=\"" + project[`id`] + "\">" + project[`name`] + "</option>";
    });
    jQuery(fieldId).html(options.join("\n"));
    jQuery(errorFieldId).hide();
    jQuery(fieldId).prop("disabled", false);
  }

  static fetchProjects(jQuery: any, customerId: string, fieldId: string, errorFieldId: string, errorCodeFieldId: string) {
    jQuery.get(`/customers/${customerId}/project_list`)
          .done((data) => this.buildSelect(jQuery, fieldId, errorFieldId, data))
          .fail((data) => this.handleFetchError(jQuery, fieldId, errorFieldId, errorCodeFieldId, data));
  }

  static handleFetchError(jQuery: any, fieldId: string, errorFieldId: string, errorCodeFieldId: string, data: any) {
    jQuery(errorFieldId).show();
    jQuery(fieldId).prop("disabled", true);
    jQuery(errorCodeFieldId).html(`(${data.status}: ${data.statusText})`);
  }
}
